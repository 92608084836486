<template>
  <div>
    <!-- Reply -->
    <div class="mt-3">
      <v-row>
        <v-col cols="1" class="pr-0 text-center">
          <img
            class="avatar"
            :src="
              avatar
                ? avatar
                : 'https://dvdn247.net/wp-content/uploads/2020/07/avatar-mac-dinh-1.png'
            "
          />
        </v-col>
        <v-col cols="11" style="padding: 0px !important">
          <client-comment-text-area
            class="mr-12 mt-2"
            v-model="contentCommenTop"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-end mr-2 mt-3">
        <v-btn
          class="btn-send mr-7"
          :disabled="contentCommenTop.length === 0"
          @click="sendComment()"
        >
          コメント
        </v-btn>
      </div>
    </div>
    <!-- End reply -->

    <!-- content -->
    <div v-if="data.length > 0">
      <!-- Pagination top -->
      <hr />
      <div class="mx-9">
        <CommnetPaginate 
          :total="getTotalCmt" 
          :itemPerPage="selected"
          :items="[5, 10, 20, 30, 40, 50, 100]"
          @reset="setTakeSkip"
        />
      </div>
      <hr />
      <!-- End pagination top -->

      <!-- content -->
      <div class="my-10">
        <div v-for="(comment, idx) in data" :key="idx" class="mx-7">
          <Comment :comment="comment" @comment="postComment" @reset="reset" page="overview"/>
        </div>
      </div>
      <!-- End content -->

      <!-- paginate bottom -->
      <hr />
      <div class="mx-9">
        <CommnetPaginate 
          :total="getTotalCmt" 
          :itemPerPage="selected"
          :items="[5, 10, 20, 30, 40, 50, 100]"
          @reset="setTakeSkip"
        />
      </div>
      <hr />
      <!-- paginate bottom -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Comment from '@/components/Comment/Comment'
import { exportTimeJapan } from '@/constants/functions.js';
import ClientCommentTextArea from '@/components/Comment/ClientCommentTextArea.vue';
import CommnetPaginate from '@/components/Comment/CommentPaginate.vue'

export default {
  name: 'commentList',
  components: {Comment, ClientCommentTextArea, CommnetPaginate},
  data() {
    return {
      // valueCmtTemp: null,
      contentCommenTop: '',
      avatar: null,
      // actionCurrent: null,
      // isOpen_div: null,
      // quote: false,
      // commentParent: '',
      // commentByID: localStorage.getItem('id'),
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      selected: 5,
      // dataAfter: [],
      // currentRow: 0,
      // startRow: 0,
      // endRow: 5,
      // dataCmt: this.data,
      take: 5,
      skip: 0,
      showRepCmt: false,
      // startSlice: 0,
      // endSlice: 5,
    };
  },
  props: {
    data: Array,
  },
  computed: {
    ...mapGetters([
      'customerDetailListAll',
      'getTotalCmt',
      'getPaginateClientComment'
    ]),

    // dataComment: {
    //   get() {
    //     return this.data.slice(this.startSlice, this.endSlice);
    //   },
    //   set(val) {
    //     this.startSlice = val.start;
    //     this.endSlice = val.end;
    //   },
    // },
  },
  watch: {
    // selected() {
    //   this.endRow = this.currentRow + this.selected;
    //   this.startSlice = this.currentRow;
    //   this.endSlice = this.currentRow + this.selected;
    //   this.dataComment = {
    //     start: parseInt(this.currentRow),
    //     end: parseInt(this.currentRow + this.selected),
    //   };
    // },

    // customerDetailListAll() {
    //   this.data = this.customerDetailListAll;
    // },
  },
  async mounted() {
    this.avatar = localStorage.getItem('profileImgUrl');
    await this.loadComments();
  },
  methods: {
    ...mapActions([
      'createClientCommentCustomerDetail',
    ]),
    exportTimeJapan,
    ...mapMutations(['setAlertSuccess', 'setCommentCustomerDetail']),

    setTakeSkip(res){
      this.take = parseInt(res.take)
      this.skip = parseInt(res.skip)
      this.reset()
    } ,

    async reset(){
      await this.$store.dispatch('getPaginateClientComment', {
        clientId: parseInt(this.$route.params.id),
        pagination: {
          take: this.take,
          skip: this.skip,
        },
      })
    },

    async loadComments () {
      await this.$store.dispatch('getPaginateClientComment', {
        clientId: parseInt(this.$route.params.id),
        pagination: {
          take: this.take,
          skip: this.skip,
        },
      })
    },

    async postComment({comment}) {
      this.setCommentCustomerDetail(comment);
      await this.createClientCommentCustomerDetail(this.$route.params.id);
      await this.loadComments();
    },

    // this.$store.dispatch('getCommetListMyPage');
    async sendComment() {
      await this.postComment({comment: this.contentCommenTop})
      this.contentCommenTop = '';
    },
  },
};
</script>

<style scoped lang="scss">
.btn-rep {
  border: 2px solid #3faf7c;
  width: 100px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.btn-quote {
  font-size: 15px;
  // color: #8a8d8c
  color: #3faf7c;
}

.btn-send {
  border: 2px solid #3faf7c;
  width: 100px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
}

.avatar {
  width: 60px;
  height: 60px;
  border: 1px solid #21212131;
  border-radius: 100px;
}
::v-deep {
  .v-input__control {
    .v-input__slot {
      display: flex !important;
      flex-direction: column !important;
    }
  }

  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    .v-select__slot {
      border-bottom: 0.5px solid #888888;
      .v-select__selections {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 12px !important;
        }
      }
      :hover > & {
        border-bottom: 0.5px solid black;
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-select-custom {
    width: 50px;
  }
  .divNone {
    display: none !important;
    margin-left: 6%;
  }
  .divBlock {
    display: block !important;
    margin-left: 6%;
  }
  .text-cus {
    color: #0c6786 !important;
  }
  .tex-btn-cus {
    color: #1873d9 !important;
    border: 0.5px solid #1873d9;
    border-radius: 4px;
    opacity: 1;
  }
}
</style>
