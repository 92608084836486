import gql from "graphql-tag";

export default {
  data() {
    return {
      staffs: [],
      groups: [],
    }
  },
  apollo: {
    getLists: {
      query: gql`
        query {
          staffList (crmRep: true) {mentionName, name}
          staffGroupList { name }
        }
      `,
      fetchPolicy: 'cache-first',
      manual: true,
      result (result) {
        if (result.data) {
          this.staffs = result.data.staffList;
          this.groups = result.data.staffGroupList
        }
      },
    },
  }
}