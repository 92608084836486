import { render, staticRenderFns } from "./FreeNotificationRegistration.vue?vue&type=template&id=c6256a96&scoped=true&"
import script from "./FreeNotificationRegistration.vue?vue&type=script&lang=js&"
export * from "./FreeNotificationRegistration.vue?vue&type=script&lang=js&"
import style0 from "./FreeNotificationRegistration.vue?vue&type=style&index=0&id=c6256a96&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6256a96",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VDatePicker,VDialog,VForm,VMenu,VSelect})
