<template>
  <span>
    {{ gender }}
  </span>
</template>

<script>
export default {
  props: {
    value: String
  },
  computed: {
    gender () {
      switch(this.value) {
        case 'Male': return '男性'
        case 'Female': return '女性'
        case 'Other': return 'その他'
        default: return '-'
      }
    }
  }
}
</script>
