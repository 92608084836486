<template>
  <main-layout>
    <v-dialog
      v-model="dialog"
      @click:outside="resetForm"
      content-class="px-5 py-4"
    >
      <v-form ref="form">
        <h1 class="text-title">空き通知登録</h1>
        <v-select
          class="select-custom-dialog mt-2"
          v-model="getIdFacilityNReg"
          :items="facilityListNotificationRegistration"
          item-value="id"
          :rules="[rules.required]"
          item-text="name"
          label="施設"
        ></v-select>
        <v-select
          v-model="getIdRoomTypeNReg"
          class="mt-5 mb-10 select-custom-dialog"
          :items="roomTypeListNReg"
          :rules="[rules.required]"
          item-value="id"
          item-text="name"
          label="部屋タイプ"
        ></v-select>
        <!-- Date picker -->
        <h5
          class="headerCustome text-10px mt-2"
          :class="checkInputDate ? '' : 'error--text'"
        >
          チェックイン日
        </h5>
        <div class="d-flex align-center">
          <i
            :class="checkInputDate ? '' : 'error--text'"
            class="icon-calender-custom fas fa-calendar-alt"
          ></i>
          <div class="d-flex align-center">
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <div>
                    <v-btn
                      outlined
                      small
                      color="var(--text_gray)"
                      class="bg--white v-btn-custom mx-2"
                      v-model="date"
                      hide-details
                      v-on="on"
                      :class="checkInputDate ? '' : 'error--text'"
                    >
                      <span class="text-10px" style="color: #000000;">{{
                        getCheckInDateCRWaitList === ''
                          ? 'XXXX/XX/XX'
                          : getCheckInDateCRWaitList
                      }}</span>
                    </v-btn>
                  </div>
                </template>
                <v-date-picker
                  v-model="date"
                  :first-day-of-week="0"
                  :min="nowDate"
                  :locale="$i18n.locale"
                  scrollable
                  @change="
                    (getCheckInDateCRWaitList = date),
                      (checkInputDate = true)
                  "
                  @input="menuDate = false"
                  class="v-date-picker-custom"
                ></v-date-picker>
              </v-menu>
          </div>
        </div>
        <!-- End date picker -->
        <v-select
          v-model="getCheckOutDateCRWaitList"
          class="select-custom-dialog mt-3"
          :rules="[rules.required]"
          style="width: 100px; height: 40px"
          :items="itemsCheckOutDateCRWaitList"
          label="宿泊日数"
        ></v-select>
        <v-select
          v-model="getIdClientInfoNReg"
          class="my-5"
          :rules="[rules.required]"
          item-value="subMemberId"
          item-text="name"
          :items="items"
          label="通知先会員"
        ></v-select>
        <div class="d-flex justify-end">
          <v-btn
            class="t-btn--red-dark mx-2 btn-exit"
            @click="closeDialog"
            small
          >
            閉じる
          </v-btn>
          <v-btn class="t-btn--prm btn-save" @click="register" small :disabled="!checkPerUser">
            登録
          </v-btn>
        </div>
      </v-form>
    </v-dialog>
  </main-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import MainLayout from '@/layouts/MainLayout';
import { checkPermissionUserCurrent } from '@/utils/permissions'

export default {
  components: { MainLayout },
  name: 'FreeNotificationRegistration',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      nowDate: new Date().toISOString().slice(0,10),
      menuDate: false,
      date: new Date().toISOString().substr(0, 10),
      rules: {
        required: value => !!value || '必須項目です。',
        arrival: value => {
          return (
            value !== '' || 'Arrival date must be after the Departure date'
          );
        },
      },
      checkInputDate: true,
    };
  },
  props: {
    visible: Boolean,
    id: Number,
  },

  computed: {
    items () {
      const result = this.clientMemberInfoNotificationRegistration
      return result
    },
    ...mapGetters([
      'facilityListNotificationRegistration',
      'idFacilityNReg',
      'roomTypeListNReg',
      'idRoomTypeNReg',
      'checkInDateCRWaitList',
      'clientMemberInfoNotificationRegistration',
      'idClientInfoNReg',
    ]),
    itemsCheckOutDateCRWaitList() {
      var arr = [];
      for (var i = 1; i <= 30; i++) {
        arr.push(i);
      }
      return arr;
    },
    // filter idFacilityNReg
    getIdFacilityNReg: {
      get() {
        return this.idFacilityNReg;
      },
      set(value) {
        this.setIdFacilityNReg(value);
        this.getRoomTypeListNotificationRegistration();
      },
    },
    // filter idRoomTypeNReg
    getIdRoomTypeNReg: {
      get() {
        return this.idRoomTypeNReg;
      },
      set(value) {
        this.setIdRoomTypeNReg(value);
      },
    },
    // filter getCheckInDateCRWaitList
    getCheckInDateCRWaitList: {
      get() {
        return this.checkInDateCRWaitList;
      },
      set(value) {
        this.setCheckInDateCRWaitList(value);
      },
    },
    // filter getCheckOutDateCRWaitList
    getCheckOutDateCRWaitList: {
      get() {
        return this.checkOutDateCRWaitList;
      },
      set(value) {
        this.setCheckOutDateCRWaitList(value);
      },
    },
    // filter IdClientInfoNReg
    getIdClientInfoNReg: {
      get() {
        return this.idClientInfoNReg;
      },
      set(value) {
        this.setIdClientInfoNReg(value);
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  created() {
    this.getFacilityListNotificationRegistration();
    this.setClientIdNreg(Number(this.id));
    this.getClientMemberInfoNotificationRegistration(Number(this.id));
  },
  methods: {
    ...mapActions([
      'getFacilityListNotificationRegistration',
      'getRoomTypeListNotificationRegistration',
      'createCancelWaitListFreeNotificationRegistration',
      'getClientMemberInfoNotificationRegistration',
    ]),
    ...mapMutations([
      'setIdFacilityNReg',
      'setIdRoomTypeNReg',
      'setCheckInDateCRWaitList',
      'setCheckOutDateCRWaitList',
      'setClientIdNreg',
      'setIdClientInfoNReg',
      'setAlertSuccess',
      'setAlertError',
      'resetData',
    ]),
    resetForm() {
      this.$refs.form.reset();
      this.getCheckInDateCRWaitList = '';
      this.checkInputDate = true;
    },
    closeDialog() {
      this.$refs.form.reset();
      this.getCheckInDateCRWaitList = '';
      this.checkInputDate = true;
      this.$emit('close');
    },
    register() {
      if (this.getCheckInDateCRWaitList === '') {
        this.checkInputDate = false;
      }
      if (this.$refs.form.validate() && this.getCheckInDateCRWaitList !== '') {
        this.createCancelWaitListFreeNotificationRegistration();
        this.$refs.form.reset();
        this.checkInputDate = true;
        this.getCheckInDateCRWaitList = '';
        // this.setAlertSuccess('活動スケジュール登録成功');
        this.$emit('close')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 300px;
    height: 460px;
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text input {
      font-size: 14px;
    }
  }
  // label:not(.normal):not(.v-label) {
  //   font-size: 10px;
  // }
  .v-select {
    .v-label {
      font-size: 12px !important;
      color: #000000;
    }
    .v-select__selection {
      font-size: 12px !important;
      color: #000000;
    }
  }
}
.headerCustome {
  font-weight: 400;
}
.btn-exit {
  width: 56px;
  height: 32px;
}
.btn-save {
  width: 68px;
  height: 32px;
}
.select-custom-dialog {
  width: 100%;
  height: 40px;
}
</style>
