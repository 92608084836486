export const listContractBackground = {
  /* DP */ 3: 'bg-contract--dp',
  /* MW */ 10: 'bg-contract--mw',
  /* PW */ 9: 'bg-contract--pw',
  /* FP */ 2: 'bg-contract--fp',
  /* SP */ 1: 'bg-contract--sp', // SP  -->  SP(PWポイント含む) => using 'includes' to check
  /* VM */ 7: 'bg-contract--vm',
  /* MO */ 6: 'bg-contract--mo',
  /* VS */ 5: 'bg-contract--vs',
  /* OTA */ 8: 'bg-contract--ota',
  /* 体験宿泊 */ 13: 'bg-contract--experience',
  /* ポイント提携施設 */ 12: 'bg-contract--point-facility',
  // /* 優待券招待券 */ 4: 'bg-contract--free-ticket',
  /* 法人チケット */ 4: 'bg-contract--corporate-ticket',
  /* その他予約 */ 11: 'bg-contract--other-reservations'
  // /* ブロック */ 11: 'bg-contract--block',
  // /* 排他制御 */ 1: 'bg-contract--exclusive-control'
}