<template>
  <blockquote>
    <CommentQuote :line="nextLine" v-if="isNextQuoted" :quoteRegex="quoteRegex"/>
    <template v-else>
      <CommentLineWithMention :message="nextLine" />
    </template>
  </blockquote>
</template>

<script>
import CommentLineWithMention from './CommentLineWithMention.vue'
export default {
  name: 'CommentQuote',
  components: {CommentLineWithMention},
  props: {
    line: String,
    quoteRegex: RegExp,
  },
  computed: {
    nextLine() {
      return this.line.replace(this.quoteRegex, '')
    },
    isNextQuoted() {
      return this.quoteRegex.test(this.nextLine)
    }
  }
}
</script>

<style lang="scss" scoped>
blockquote {
  margin: 0px 0px 0px 0.8ex; 
  border-left: 1px solid rgb(204, 204, 204); 
  padding-left: 1ex;
}
</style>