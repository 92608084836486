<template>
  <v-dialog v-model="showDialog" persistent width="1200px">
    <v-form ref="form">
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between w-100">
            <div class="title-dialog">{{nameClient}}新規法人チケット発行</div>
            <div class="d-flex text--medium">
              <div>コピー元チケットNo:</div>
              <v-text-field v-model="copyFrom" class="mx-2" dense />
              <v-btn @click="copy" :disabled="!copyFrom"
                class="t-btn--prm mx-1">コピーして使う</v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row class="pa-10 pb-0">
            <v-col :cols="4" class="pr-8 list-item">
              <div>
                <span class="title-item">{{ $t("common.title") }}</span>
                <v-text-field
                  value="number"
                  :rules="rules.nameTicket"
                  v-model="nameTicket"
                  dense
                />
              </div>
              <div>
                <span class="title-item">{{
                  $t("common.numberOfSheets")
                }}</span>
                <v-text-field
                  :rules="rules.numberOfTickets"
                  v-model="numberOfTickets"
                  dense
                />
              </div>
              <div>
                <span class="title-item">{{ $t("common.facility") }}</span>
                <v-select
                  multiple
                  :rules="rules.facility"
                  @change="onFacilitySelectChange"
                  item-text="name"
                  item-value="id"
                  :items="facilityList"
                  v-model="facilityIds"
                  dense
                />
              </div>
              <div>
                <span class="title-item">{{ $t("common.roomType") }}</span>
                <v-select
                  :rules="rules.roomTypeIds"
                  v-model="roomTypeIds"
                  :items="listRoomType"
                  item-value="id"
                  item-text="name"
                  multiple
                  dense
                />
              </div>
            </v-col>
            <v-col :cols="4" class="list-item">
              <div>
                <span class="title-item">{{ $t("common.date") }}</span>
                <div class="d-flex custom-picker">
                  <v-icon>mdi-calendar-month</v-icon>
                  <v-menu
                    v-model="menuShowDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <div class="custom-btn-picker">
                        <v-btn
                          outlined
                          :rules="rules.fromDate"
                          v-model="fromDate"
                          v-on="on"
                          small
                          class="bg-white--btn mr-5"
                          >{{ formatDate(fromDate) }}</v-btn
                        >
                      </div>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      @change="changeFromDate($event)"
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      @input="menuShowDate = false; menuShowTodate = true"
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                  <!--  -->
                  <span class="pl-1 pr-1">〜</span>
                  <v-menu
                    v-model="menuShowTodate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <div class="custom-btn-picker">
                        <v-btn
                          outlined
                          v-on="on"
                          small
                          class="bg-white--btn mr-5"
                          >{{ formatDate(toDate) }}</v-btn
                        >
                      </div>
                    </template>
                    <v-date-picker
                      :rules="rules.toDate"
                      :min="minToDate"
                      v-model="toDate"
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      @input="menuShowTodate = false"
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                  <!--  -->
                </div>
              </div>

              <div class="mt-3 user-or-not">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.reservedPath")
                }}</span>
                <v-checkbox
                  hide-details
                  v-model="bookCallCenter"
                  :label="$t('invitationsAndCoupons.callCenter')"
                ></v-checkbox>
                <v-checkbox
                  v-model="bookSalesRep"
                  hide-details
                  :label="$t('invitationsAndCoupons.sales')"
                ></v-checkbox>
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.dayOfWeek")
                }}</span>
                <div class="d-flex user-or-not card-cus_chkbox">
                  <v-checkbox
                    v-model="monday"
                    hide-details
                    :label="$t('weekdays.monday')"
                  />
                  <v-checkbox
                    v-model="tuesday"
                    hide-details
                    :label="$t('weekdays.tuesday')"
                  />
                  <v-checkbox
                    v-model="wednesday"
                    hide-details
                    :label="$t('weekdays.wednesday')"
                  />
                  <v-checkbox
                    v-model="thursday"
                    hide-details
                    :label="$t('weekdays.thursday')"
                  />
                </div>
                <div class="d-flex user-or-not card-cus_chkbox">
                  <v-checkbox
                    v-model="friday"
                    hide-details
                    :label="$t('weekdays.friday')"
                  />
                  <v-checkbox
                    v-model="saturday"
                    hide-details
                    :label="$t('weekdays.saturday')"
                  />
                  <v-checkbox
                    v-model="sunday"
                    hide-details
                    :label="$t('weekdays.sunday')"
                  />
                  <v-checkbox
                    v-model="holiday"
                    hide-details
                    :label="$t('weekdays.holidays')"
                  />
                </div>
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.exclusionDay")
                }}</span>
                <v-menu
                  v-model="excludingDayMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex align-center">
                      <v-icon>mdi-calendar-month</v-icon>
                      <v-text-field
                        v-model="excludingDay"
                        v-on="on"
                        clearable
                        readonly
                        class="ml-5 pt-0"
                        >{{ excludingDay }}</v-text-field
                      >
                    </div>
                  </template>
                  <v-date-picker
                    multiple
                    v-model="excludingDay"
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    scrollable
                    class="v-date-picker-custom"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mt-3 user-or-not">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.userOrNot")
                }}</span>
                <v-checkbox
                  v-model="nightwear"
                  hide-details
                  :label="$t('invitationsAndCoupons.nightwear')"
                ></v-checkbox>
                <v-checkbox
                  v-model="toothbrush"
                  hide-details
                  :label="$t('invitationsAndCoupons.toothbrush')"
                ></v-checkbox>
                <v-checkbox
                  v-model="razor"
                  hide-details
                  :label="$t('invitationsAndCoupons.shaving')"
                ></v-checkbox>
                <v-checkbox
                  v-model="rentalItem"
                  hide-details
                  :label="$t('invitationsAndCoupons.itemAvailableRent')"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col :cols="4" class="pl-8 list-item">
              <div class="user-or-not">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.amountUsed")
                }}</span>
                <v-checkbox
                  @change="checkStatusFree($event)"
                  v-model="statusFree"
                  hide-details
                  :label="$t('invitationsAndCoupons.free')"
                ></v-checkbox>
                <div class="d-flex align-end">
                  <v-checkbox
                  @change="checkStatusFree2($event)"
                    hide-details
                    v-model="statusFree2"
                    :label="$t('invitationsAndCoupons.fixedFree')"
                    class="flex-shrink-0"
                  ></v-checkbox>
                  <span
                    class="ml-2 font-weight-bold flex-shrink-0 mr-3"
                    style="font-size: 8px"
                    >{{ $t("invitationsAndCoupons.taxInclude") }}</span
                  >
                  <tvos-int-input
                    v-if="!disabled"
                    jpy
                    v-model="accommodationPrice"
                    class="w-100"
                  />
                  <v-text-field prefix="¥ " value="0" disabled v-else>

                  </v-text-field>
                </div>
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.cancelPolicy")
                }}</span>
                <v-select
                  :rules="rules.cancelDeadline"
                  v-model="cancelDeadline"
                  :items="itemCancelDate"
                  dense
                />
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.reservationStartDate")
                }}</span>
                <v-menu
                  v-model="contractStartDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex align-center">
                      <v-icon>mdi-calendar-month</v-icon>
                      <v-text-field
                        :rules="rules.contractStartDate"
                        v-model="contractStartDate"
                        v-on="on"
                        clearable
                        readonly
                        class="ml-5 pt-0"
                        >{{ contractStartDate }}</v-text-field
                      >
                    </div>
                  </template>
                  <v-date-picker
                    v-model="contractStartDate"
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    scrollable
                    @input="contractStartDateMenu = false"
                    class="v-date-picker-custom"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mt-5">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.reservationPeriod")
                }}</span>
                <div class="d-flex justify-space-between align-baseline">
                  <div>宿泊</div>
                  <div style="width: 80px">
                    <v-select
                      :rules="rules.bookingFromMonths"
                      v-model="bookingFromMonths"
                      :items="bookingFromMonthsSelect"
                      dense
                      width="80"
                    />
                  </div>
                  <div>か月前</div>
                  <div style="width: 80px">
                    <v-select
                      :rules="rules.bookingFromDays"
                      v-model="bookingFromDays"
                      :items="bookingFromDaysSelect"
                      dense
                    />
                  </div>
                  <div>日前</div>
                </div>
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.amountRightReserved")
                }}</span>
                <tvos-int-input
                  v-model="assetValue"
                  class="w-100"
                  jpy
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="px-10 list-item">
            <div style="width: 100%">
              <span class="title-item">{{ $t("common.remark") }}</span>
              <v-text-field dense v-model="notes" />
            </div>
          </v-row>
        </v-card-text>
        <v-card-text class="mt-5">
          <v-row class="mt-1">
            <v-col :cols="6" class="d-flex justify-start"> </v-col>
            <v-col :cols="6" class="d-flex justify-end">
              <v-btn
                color="#C92D2D"
                class="mr-3 d-flex align-center btn_status white--text"
                @click="closeDialog"
              >
                <v-icon class="white--text"> mdi-close </v-icon>
                閉じる
              </v-btn>
              <v-btn
                class="white--text"
                color="#1873D9"
                @click="actionCreateNew()"
              >
                保存
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { formatDate, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { FACLILITY_LIST } from '@/api/graphql/corporateTicket/new-ticket'
import { mapMutations, mapActions } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'CreateInvitationAndCoupon',
  props: {
    showDialog: Boolean
  },
  data () {
    return {
      nameClient: '',
      disabled: false,
      statusFree2: true,
      statusFree: null,
      facilityList: [],
      listRoomType: [],
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      holiday: false,
      assetValue: 0,
      cancelDeadline: null,
      bookingFromMonths: null,
      bookingFromDays: null,
      roomTypeIds: null,
      facilityIds: [],
      nightwear: false,
      toothbrush: false,
      bookCallCenter: false,
      bookSalesRep: false,
      razor: false,
      rentalItem: false,
      accommodationPrice: 0,
      minFromDate: new Date().toISOString().substr(0, 10),
      minToDate: new Date().toISOString().substr(0, 10),
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      menuShowDate: false,
      menuShowTodate: false,
      intendedClientId: null,
      nameTicket: '',
      numberOfTickets: null,
      copyFrom: null,
      rules: {
        nameTicket: [
          (v) => !!v || this.$t('rules.isRequired'),
          (v) => v?.length < 256 || this.$t('rules.isInvalid')
        ],
        numberOfTickets: [(v) => !!v || this.$t('rules.isRequired'),
          (v) => v < 1000 || this.$t('rules.isInvalid'),
          (v) => Number.isInteger(parseInt(v)) || this.$t('rules.isNumber')],
        roomTypeIds: [(v) => !!v || this.$t('rules.isRequired')],
        fromDate: [(v) => !!v || this.$t('rules.isRequired'),
          (v) => v >= new Date().toISOString().substr(0, 10) || this.$t('rules.isInvalid')
        ],
        facility: [
          (v) => v.length > 0 || this.$t('rules.isRequired')
        ],
        toDate: [(v) => !!v || this.$t('rules.isRequired')],
        excludingDay: [(v) => !!v || this.$t('rules.isRequired')],
        cancelDeadline: [(v) => !!v || this.$t('rules.isRequired')],
        contractStartDate: [(v) => !!v || this.$t('rules.isRequired')],
        bookingFromMoonths: [(v) => typeof v === 'number' || this.$t('rules.isRequired')],
        bookingFromDays: [(v) => typeof v === 'number' || this.$t('rules.isRequired')],
      },
      menuDate: [],
      listDate: [
        { id: 1, date: new Date().toISOString().substr(0, 10) },
        { id: 2, date: new Date().toISOString().substr(0, 10) }
      ],
      excludingDayMenu: false,
      excludingDay: [],
      contractStartDateMenu: false,
      contractStartDate: new Date().toISOString().substr(0, 10),
      taxIncludeFee: 0,
      amountFee: 0,
      notes: null,
    }
  },
  watch: {
    showDialog () {
      this.monday = false
      this.tuesday = false
      this.wednesday = false
      this.thursday = false
      this.friday = false
      this.saturday = false
      this.sunday = false
      this.holiday = false
      this.assetValue = 0
      this.cancelDeadline = null
      this.bookingFromDays = null
      this.bookingFromMonths = null
      this.roomTypeIds = null
      this.facilityIds = []
      this.nightwear = false
      this.toothbrush = false
      this.bookCallCenter = false
      this.bookSalesRep = false
      this.razor = false
      this.rentalItem = false
      this.accommodationPrice = 0
      this.fromDate = new Date().toISOString().substr(0, 10)
      this.toDate = new Date().toISOString().substr(0, 10)
      this.menuShowDate = false
      this.menuShowTodate = false
      this.nameTicket = ''
      this.numberOfTickets = null
      this.intendedClientId = null
    },
  },

  computed: {
    itemCancelDate () {
      const data = [...Array(100)].map((_, ind) => ({
        text: `宿泊日から${ind + 1}日前以降`,
        value: ind + 1
      }))
      return data
    },
    bookingFromDaysSelect () {
      return [...Array(101)].map((_, ind) => ({
        text: ind,
        value: ind
      }))
    },
    bookingFromMonthsSelect () {
      return [...Array(7)].map((_, ind) => ({
        text: ind,
        value: ind
      }))
    },
  },

  created () {
  },
  mounted () {
    this.getFacilityList()
    this.getClientBasicName()
  },

  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations(['setCopyErrorText', 'setInvitationTicket', 'setAlertError', 'setPermissionUser', 'setRoleAdminUser']),
    ...mapActions(['createBookingTickets']),

    checkNumber (v) {
      return Number.isInteger(parseInt(v))
    },

    checkStatusFree (event) {
      if (event) {
        this.disabled = true
        this.statusFree = true
        this.statusFree2 = false
        this.accommodationPrice = 0
      } else {
        this.disabled = false
        this.statusFree2 = true
        this.statusFree = false
      }
    },
    checkStatusFree2 (event) {
      if (event) {
        this.disabled = false
        this.statusFree = false
        this.statusFree2 = true
      } else {
        this.disabled = true
        this.statusFree = true
        this.statusFree2 = false
        this.accommodationPrice = 0
      }
    },

    onFacilitySelectChange () {
      this.getSelectFacility()
      this.cleanRoomTypeIds()
    },

    getSelectFacility () {
      this.listRoomType = []
      for (let i = 0; i < this.facilityList.length; i++) {
        for (let j = 0; j < this.facilityIds.length; j++) {
          if (this.facilityIds[j] === this.facilityList[i].id) {
            this.listRoomType = this.listRoomType.concat(this.facilityList[i].roomTypes)
          }
        }
      }
    },

    cleanRoomTypeIds() {
      // make sure the selected room type ids exist in the new selection options
      this.roomTypeIds = this.roomTypeIds.filter(id => this.listRoomType.map(rt => rt.id).includes(id))
    },

    async getFacilityList () {
      await this.$apollo
        .query({
          query: gql`${FACLILITY_LIST}`
        })
        .then((data) => {
          this.facilityList = data.data.facilityList
          return this.getSelectFacility()
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(FACLILITY_LIST, null, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
    },

    changeFromDate (event) {
      const result = new Date(event)
      const addRes = new Date(event).setDate(result.getDate() + 1)
      const date = new Date(addRes)
      this.minToDate = date.toISOString().substr(0, 10)
      this.toDate = date.toISOString().substr(0, 10)
    },

    formatDate,

    async getClientBasicName () {
      await this.$apollo
        .query({
          query: gql`
            query ($id: Int!) {
            clientBasicInfo(id: $id){
              id
              name
            }
          }`,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
        .then((data) => {
          this.nameClient = data.data.clientBasicInfo.name
        })
        .catch( (error) => {
        console.log(error);
        });
    },
  
    async actionCreateNew () {
      if (this.$refs.form.validate()) {
        if (!this.bookingFromMonths && !this.bookingFromDays) {
          this.$store.commit('setAlertError', '予約可能期間が不正です')
          return;
        }
        const invitationTicket = {
          name: this.nameTicket,
          numberOfTickets: parseInt(this.numberOfTickets),
          targetRoomTypeIds: this.roomTypeIds,
          availableFromDate: this.fromDate,
          availableToDate: this.toDate,
          bookCallCenter: this.bookCallCenter,
          bookSalesRep: this.bookSalesRep,
          availableMonday: this.monday,
          availableTuesday: this.tuesday,
          availableWednesday: this.wednesday,
          availableThursday: this.thursday,
          availableFriday: this.friday,
          availableSaturday: this.saturday,
          availableSunday: this.sunday,
          availableHoliday: this.holiday,
          exceptionDates: this.excludingDay,
          nightwear: this.nightwear,
          toothbrush: this.toothbrush,
          rentalItem: this.rentalItem,
          razor: this.razor,
          accommodationPrice: this.accommodationPrice,
          cancelDeadline: this.cancelDeadline,
          bookingFromDate: this.contractStartDate,
          bookingFromMonths: this.bookingFromMonths,
          bookingFromDays: this.bookingFromDays,
          assetValue: this.assetValue,
          // intendedClientId: this.$route.query.idClient
          intendedClientId: parseInt(this.$route.params.id),
          notes: this.notes
        }
        this.setInvitationTicket({ invitationTicket: invitationTicket })
        await this.createBookingTickets()
        this.closeDialog()
      }
    },

    closeDialog () {
      this.$emit('close')
    },

    async copy() {
      await this.$store.dispatch('fetchBookingTicketType', this.copyFrom);
      const got = this.$store.getters.bookingTicketTypeDetail;
      this.nameTicket = got.name
      this.numberOfTickets = got.computed.issuedCount
      this.facilityIds = [...new Set(got.roomTypes.map(rt => rt.roomType.facility.id))]
      this.roomTypeIds = got.roomTypes.map(rt => rt.roomType.id);
      this.excludingDay = got.exceptDates.map(d => d.exceptDate)
      this.fromDate = got.availableFromDate
      this.toDate = got.availableToDate
      this.bookCallCenter = got.bookCallCenter
      this.bookSalesRep = got.bookSalesRep
      this.monday = got.availableMonday
      this.tuesday = got.availableTuesday
      this.wednesday = got.availableWednesday
      this.thursday = got.availableThursday
      this.friday = got.availableFriday
      this.saturday = got.availableSaturday
      this.sunday = got.availableSunday
      this.holiday = got.availableHoliday
      this.nightwear = got.nightwear
      this.toothbrush = got.toothbrush
      this.razor = got.razor
      this.rentalItem = got.rentalItem
      this.accommodationPrice = got.accommodationPrice
      this.cancelDeadline = got.cancelDeadline
      this.contractStartDate = got.bookingFromDate
      this.bookingFromDays = got.bookingFromDays
      this.bookingFromMonths = got.bookingFromMonths
      this.assetValue = got.assetValue
      this.notes = got.notes
      this.statusFree = !this.accommodationPrice
      this.statusFree2 = !this.statusFree
      this.getSelectFacility()
    }
  }
}
</script>

<style scoped lang="scss">
.list-item::v-deep {
  div {
    font-size: 14px;
    span {
      &:first-child {
        font-size: 10px !important;
        font-weight: bold !important;
      }
      &.v-btn__content {
        font-size: 13px !important;
        font-weight: unset !important;
      }
    }
    .v-label {
      font-size: 14px;
    }
    .v-input--checkbox {
      margin: 0 0;
    }
  }
}
.unDisabledInput {
  display: none;
}
.disabledInput {
  display: block;
}

::v-deep {
  .v-input--selection-controls__input .v-label .theme--light {
    color: red !important
  }
  .custom-picker .v-size--small {
    color: #ffffff;
    border: solid 1px #000000;
  }
  .custom-picker .v-btn__content {
    color: #000000;
  }
  .custom-btn-picker .v-size--small {
    margin: 0 !important;
    width: 115px !important;
  }
  .list-item div span.v-btn__content {
    font-size: 10px !important;
  }
  .mdi-calendar-month::before {
    color: #333333;
  }
  .user-or-not .v-label {
    color: #000000;
  }
  .v-list-item__title {
    font-size: 14px !important;
  }
  .v-list-item__action {
    margin: 0;
  }
  .card-cus {
    &_chkbox {
      .v-input__slot {
        margin-right: 30px;
      }
    }
  }
}
.size-text-notice {
  font-size: 10px;
}
.title-dialog {
  font-size: 20px;
  font-weight: bold !important;
  color: #000000;
}
.title-item {
  color: #212121;
}
</style>
