<template>
  <div>
    <div class="header-main d-flex justify-space-between" v-if="typeClient === TYPE_CLIENT.VSINTEREST && nameRouteCurrent === 'prospect-information'">
      <h1 class="my-4 ml-10 page-title-main">{{nameClient}} : VS資料請求のみ</h1>
    </div>

    <div class="header-main d-flex justify-space-between" v-else>
      <div class="d-flex align-center">
        <h1 class="my-4 ml-10 page-title-main">{{nameClient}}</h1>
        <div class="customer-chips ml-2">
          <v-chip v-for="(chip, ind) in chips" :key="ind" :color="chip.color" :text-color="chip.textColor">{{chip.text}}</v-chip>
        </div>
      </div>
      <div class="mt-3 mr-4">
        <v-btn style="font-size: 12px !important; height:32px !important; width:151px !important" class="t-btn--prm mx-2" @click="redirectPMS" :disabled="!checkPerUser">
          <i class="fas fa-plus" style="margin-right: 10px"></i
          >{{ $t('task_29786.CreateNewAccommodationReservation') }}
        </v-btn>
        <v-btn style="font-size: 12px !important; height:32px !important; width:72px !important" class="t-btn--red-dark mx-2" @click="$windowClose">
          <v-icon class="mt-1">mdi-close</v-icon>
          {{ $t('task_29786.close') }}
        </v-btn>
        <!-- <v-btn :disabled="!checkPerUser" v-if="typeShow === TYPE_CLIENT.VSINTEREST || nameRouteCurrent !== 'prospect-information'" @click="setStatusSaveData()" class="t-btn--prm vs-vm-button">
          <span>保存</span>
        </v-btn> -->
        <!-- <v-btn class="t-btn--prm vs-vm-button">
          <span> 保存</span>
        </v-btn> -->
        <slot name="btnPrope" />
      </div>
    </div>
    <customer-details-tab :id="idClient" :typeClient="typeClient"></customer-details-tab>
    <div class="mx-10" v-if="basicInfo">
      <slot name="default" :typeClient="typeClient" :id="idClient" />
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import CustomerDetailsTab from './CustomerDetailsTab.vue';
import { TYPE_CLIENT } from '@/constants/enum'
import { CLIENT_BASIC_INFO_CRRENT } from '@/api/graphql/basicInformation/basicInformation'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { cardExpireMonths, cardExpireToDate } from '@/utils/dateUtil'
import gql from 'graphql-tag'

export default {
  components: { CustomerDetailsTab },
  name: 'CustomerDetails',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      TYPE_CLIENT,
      status: false,
      typeShow: null,
      nameRouteCurrent: this.$route.name,
      basicInfo: null,
      savedCard: null
    };
  },
  computed: {
    idClient () {
      return this.$route.params.id ? parseInt(this.$route.params.id): null
    },
    nameClient () {
      return this.basicInfo?.name ?? ''
    },
    typeClient () {
      return this.basicInfo?.type ?? (this.$route.query.typeClient || '')
    },
    chips() {
      const chips = []
      if (!this.savedCard) {
        chips.push({
          text: 'カード未登録',
          color: 'error'
        });
      } else if (this.savedCard.expire) {
        const expireMonths = cardExpireMonths(this.savedCard.expire)
        const text = (msg) => {
          return `${msg} (${cardExpireToDate(this.savedCard.expire)})`
        }
        if (expireMonths < 0) {
          chips.push({
            text: text('カード期限切'),
            color: 'error'
          });
        } else if (expireMonths < 3) {
          chips.push({
            text: text('カード期限近'),
            color: 'warning'
          });
        } else {
          chips.push({
            text: text('カード登録済'),
            color: 'success'
          })
        }
      }

      return chips;
    }
  },
  watch: {
    nameClient: function(val){
      document.title = val;
    },

    idClient () {
      this.getUnserInfoClientCurrent(parseInt(this.idClient))
    }
  },
  mounted (){
    if (this.idClient) {
      this.getUnserInfoClientCurrent(parseInt(this.idClient))
    }
  },

  methods:{
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setStatusSaveData',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    redirectPMS() {
      const url = `${process.env.VUE_APP_URL_PMS}/reservation/calendar?id=${this.$route.params.id}&fromPage=crm`
      window.open(url)
    },
    async getUnserInfoClientCurrent () {
      const variables = {
        id: this.idClient
      }
      await this.$apollo.query({
        query: gql`${CLIENT_BASIC_INFO_CRRENT}`,
        variables: variables
      }).then(async (data) => {
        this.basicInfo = data.data.clientBasicInfo
        this.savedCard = data.data.savedCard
      }).catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(CLIENT_BASIC_INFO_CRRENT, variables, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.header-main {
  display: flex;
  background-color: #f8f8f8;
  color: #000000;
  opacity: 1;
}

.px-main {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #21212131;
  border-radius: 4px;
  opacity: 1;
  min-height: 80vh;
  padding: 10px;
}
.p-relative {
  position: relative;
}
.flex-header {
  display: flex;
}
::v-deep {
  &.search-results {
    position: absolute;
    top: calc(100% - 10px);
    right: 173px;
    width: 400px;
    max-height: 552px;
    overflow-x: auto;
    z-index: 1;
    &__item:not(:last-child) {
      border-bottom: solid 1px var(--border__white);
    }
  }
  .t-search {
    margin-top: 0;
    padding: 0;
    max-width: 240px;
  }
  .page-title-main {
    font-weight: bold;
    font-size: 20px;
    background-color: var(--text__dark) !important;
    text-transform: uppercase !important;
  }
}
</style>
