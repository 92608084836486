<template>
  <Table
    :attr="{
      dense: true,
      'item-class': itemRowBackground,
      'item-key': 'id',
    }"
    ref="table"
    :multiSort="true"
    :itemsPerPage="10"
    :itemsPerPageOptions="[5, 10, 20, 50, 100, 200, 500]"
    :headers="headers"
    :total="total"
    :items="bookings"
    :funReset="fetchData"
  >
    <template v-slot:[`item.action`]="{ item }">
      <v-btn
        :disabled="!checkPerUser"
        outlined
        small
        style="min-width: 40px !important; width: 40px !important; height: 24px !important"
        target="_blank"
        @click="$openRoute.booking(item.id)"
        color="#1873D9"
        class="bg--white"
      >
        <span style="font-size:10px">{{ $t('buttons.edit') }}</span>
      </v-btn>
    </template>
    <template v-slot:[`item.computed.status`]="{ item }">
      {{ item.computed ? item.computed.status : '' }}
    </template>
    <template v-slot:[`item.bookingType.code`]="{ item }">
      {{ item.bookingType.code }}
    </template>
    <template v-slot:[`item.room.roomType.facility.name`]="{ item }">
      {{
        item.room.roomType.facility
          ? item.room.roomType.facility.name
          : ''
      }}
    </template>
    <template v-slot:[`item.room.roomType.name`]="{ item }">
      {{ item.room.roomType.name }}
    </template>
    <template v-slot:[`item.room.name`]="{ item }">
      {{ item.room.name }}
    </template>
    <template v-slot:[`item.bookingPlan.title`]="{ item }">
      {{ item.bookingPlan ? item.bookingPlan.title : '' }}
    </template>
    <template v-slot:[`item.checkInDate`]="{ item }">
      {{ item.checkInDate }}
    </template>
    <template v-slot:[`item.checkOutDate`]="{ item }">
      {{ item.checkOutDate }}
    </template>
    <template v-slot:[`item.representative.name`]="{ item }">
      <template v-if="item.representative">
        {{ item.representative.name ? item.representative.name : '' }}
      </template>
    </template>
    <template v-slot:[`item.adult`]="{ item }">
      {{ item.computedForList.adults }}
    </template>
    <template v-slot:[`item.child`]="{ item }">
      {{ item.computedForList.children }}
    </template>
    <template v-slot:[`item.bedSharing`]="{ item }">
      {{ item.computedForList.bedShares }}
    </template>
    <template v-slot:[`item.parking`]="{ item }">
      {{ item.computedForList.hasParking ? '有' : '無' }}
    </template>
    <template v-slot:[`item.rentalItem`]="{ item }">
      {{ item.computedForList.hasRentalItem ? '有' : '無' }}
    </template>
    <template v-slot:[`item.updatedAt`]="{ item }">
      {{ item.updatedAt | formatDateTimeISONoSecond }}
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">
      {{ item.createdAt | formatDateTimeISONoSecond }}
    </template>
  </Table>
</template>

<script>
import Table from '@/components/Table/index.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { listContractBackground } from '@/constants/contractColors';

export default {
  components: {
    Table
  },
  props: {
    clientId: Number,
  },
  data() {
    return {
      bookingListResponse: null,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          value: 'action',
          sortable: false,
        },
        {
          text: '予約ID',
          value: 'id',
          fieldName: 'booking.id',
          sortable: true,
        },
        {
          text: this.$t('common.status'),
          value: 'computed.status',
          sortable: true,
        },
        {
          text: this.$t('common.contract'),
          value: 'bookingType.code',
          fieldName: 'btype.code',
          sortable: true,
        },
        {
          text: this.$t('common.facility'),
          value: 'room.roomType.facility.name',
          fieldName: 'facility.name',
          sortable: true,
        },
        {
          text: this.$t('common.roomType'),
          value: 'room.roomType.name',
          fieldName: 'roomType.name',
          sortable: true,
        },
        {
          text: this.$t('common.room'),
          value: 'room.name',
          sortable: true,
        },
        {
          text: 'プラン',
          value: 'bookingPlan.title',
          fieldName: 'plan.title',
          sortable: true,
        },
        {
          text: this.$t('common.checkIn'),
          value: 'checkInDate',
          fieldName: 'booking.checkInDate',
          sortable: true,
        },
        {
          text: this.$t('common.checkOut'),
          value: 'checkOutDate',
          fieldName: 'booking.checkOutDate',
          sortable: true,
        },
        {
          text: this.$t('common.representative'),
          value: 'representative.name',
          fieldName: 'rep.name',
          sortable: true,
        },
        {
          text: this.$t('common.adult'),
          value: 'adult',
          fieldName: 'computedForList.adults',
          sortable: true,
        },
        {
          text: '子供',
          value: 'child',
          fieldName: 'computedForList.children',
          sortable: true,
        },
        {
          text: '添寝',
          value: 'bedSharing',
          fieldName: 'computedForList.bedShares',
          sortable: true,
        },
        {
          text: '駐車場',
          value: 'parking',
          fieldName: 'computedForList.hasParking',
          sortable: true,
          align: 'center',
        },
        {
          text: '貸出品',
          value: 'rentalItem',
          fieldName: 'computedForList.hasRentalItem',
          sortable: true,
          align: 'center',
        },
        {
          text: '最終変更日時',
          value: 'updatedAt',
          fieldName: 'booking.updatedAt',
          sortable: true,
        },
        {
          text: '予約日',
          value: 'createdAt',
          fieldName: 'booking.createdAt',
          sortable: true,
        },
      ]
    },
    total () {
      return this.bookingListResponse?.total ?? 0
    },
    bookings () {
      return this.bookingListResponse?.items
    }
  },
  methods: {
    async fetchData(options) {
      const {orderBy, pagination} = options
      const response = await this.$store.dispatch('fetchBookingList', {
        take: pagination.take,
        skip: pagination.skip,
        orderBy: orderBy,
        clientId: this.clientId
      });
      this.bookingListResponse = response;
    },
    itemRowBackground(item) {
      const btypeId = item.bookingType.id;
      return listContractBackground[btypeId];
    },
  }
}
</script>