<template>
  <div>
    <div class="d-flex justify-end mt-3 mb-5" style="width: 100%">
      <div class="mt-2">
        {{ total }}
        件のうち{{ startRow }}〜{{ endRow }}件を表示
      </div>
      <div>
        <v-select
          :items="items"
          class="v-select-custom mx-3 mt-1"
          v-model="selected"
          outlined
        ></v-select>
      </div>
      <div class="mt-1">
        <button
          :disabled="currentPage === 0"
          :style="currentPage === 0 ? 'color: #bfbfbf' : ''"
          style="font-size: 20px;"
          @click="action(0)"
        >
          <i class="fas fa-chevron-left mr-5"></i>
        </button>
        <button
          :disabled="page === currentPage"
          :style="page === currentPage ? 'color: #bfbfbf' : ''"
          style="font-size: 20px;"
          @click="action(1)"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'commnetPaginate',
  data() {
    return {
      startRow: 1,
      endRow: 5,
      skip: 0,
      select: this.itemPerPage,
      arrNumPaginate: [],
      currentPage: 0,
      page: 0
    };
  },
  props: {
    total: Number,
    itemPerPage: Number,
    items: Array
  },
  computed: {
    selected: {
      get() {
        return this.select;
      },
      set(val) {
        this.skip = 0;
        this.endRow = val;
        this.select = val;
        this.currentPage = 0;
        this.getNumPage();
        this.$emit('reset', {
            take: this.selected,
            skip: this.skip
        })
      },
    },
  },
  watch: {
    total(){
      this.getNumPage();
    }
  },
  mounted() {
    this.getNumPage();
  },
  methods: {
    getNumPage() {
      const page = parseInt(this.total / this.select);
      this.page = page;
      var arr = [];
      let start = 1;
      let end = this.select;
      for (let i = 0; i <= page; i++) {
        if (i > 0) {
          start += this.select;
          end += this.select;
        }
        if (end > this.total) {
          end = this.total;
        }
        arr.push({
          start: start,
          end: end,
        });
      }
      this.arrNumPaginate = arr;
    },

    action(val) {
      // val === 1 : next
      // val === 0 : back
      if (val === 1) {
        this.currentPage += 1;
        this.skip += this.selected
      } else {
        this.currentPage -= 1;
        this.skip -= this.selected
      }
      this.startRow = this.arrNumPaginate[this.currentPage].start;
      this.endRow = this.arrNumPaginate[this.currentPage].end;
      this.$emit('reset', {
        take: this.selected,
        skip: this.skip
      })
    },
  },
};
</script>

<style scoped lang="scss">
</style>
