<template>
  <div>
    <div class="d-flex justify-space-between mb-3" style="width: 100%">
      <!-- Avatar -->
      <div style="width: 6%">
        <img
          class="ml-2 mt-1 avatar"
          :src="
            comment.commentBy
              ? comment.commentBy.profileImgUrl
              : 'https://dvdn247.net/wp-content/uploads/2020/07/avatar-mac-dinh-1.png'
          "
        />
      </div>
      <!-- End avatar -->

      <!-- Div rep comment -->
      <div style="width: 94%" class="px-2">
        <!-- Top -->
        <div
          class="d-flex justify-space-between px-4 py-3"
          style="border: 1px solid #21212131"
        >
          <div style="font-size: 15px; color: #3faf7c">
            {{ comment.commentBy ? comment.commentBy.name : '' }}
          </div>
          <div style="font-size: 16px; color: #a3aab1">
            {{ exportTimeJapan(comment.createdAt) }}
          </div>
        </div>
        <!-- End top -->

        <!-- Content comment -->
        <div
          class="px-4 py-3"
          style="border: 1px solid #21212131"
        >
          <!-- Text -->
          <div>
            <template v-for="(line, index) in lines">
              <comment-line :line="line" :key="`line-${index}`" />
            </template>
          </div>
          <!-- End text -->
        </div>
        <!-- End content comment -->

        <!-- Bottom -->
        <div
          class="d-flex justify-space-between px-4 py-3"
          style="border: 1px solid #21212131"
        >
          <CommentLike :comment="comment" :page="page" @reset="() => { $emit('reset') }" />
          <!-- <div @click="changeColorBtnLike(comment.id)">
            <i 
              class="fa-heart"
              :class="comment.likedByStaff ?'fas':'far'"
              :id="'like-' + comment.id"
              style = "font-size: 15px; color: #3faf7c"
            ></i>
          </div> -->
          <div class="btn-quote">
            <button @click="quoteToReply">
              <i class="fas fa-reply"></i>
            </button>
          </div>
        </div>
        <!-- End bottom -->
      </div>
      <!-- End div rep comment -->
    </div>
    <!-- End content -->

    <!-- Div rep cmt -->
    <!-- Quote -->
    <div
      v-if="showReplyForm"
      class="d-flex justify-end div-repCmt"
    >
      <v-row>
        <v-col cols="1" class="pl-5">
          <div>
            <img
              class="mt-1 avatar"
              src="https://dvdn247.net/wp-content/uploads/2020/07/avatar-mac-dinh-1.png"
            />
          </div>
        </v-col>
        <v-col cols="11">
          <div class="pr-2" style="width: 100%">
            <client-comment-text-area
              ref="commentTextArea"
              background-color="#F5FBF8"
              outlined
              v-model="replyText"
              autofocus
            />
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-end mr-2">
        <v-btn
          :disabled="replyText && replyText.length === 0"
          @click="sendReply"
          class="btn-send"
        >
          コメント
        </v-btn>
      </div>
    </div>
    <!-- End quote -->
  </div>
</template>

<script>
import { exportTimeJapan } from '@/constants/functions.js';
import CommentLine from './CommentLine.vue';
import CommentLike from './CommentLike.vue';
import ClientCommentTextArea from './ClientCommentTextArea.vue';

export default {
  components: { CommentLine, CommentLike, ClientCommentTextArea },
  props: {
    comment: Object,
    page: String
  },
  data() {
    return {
      showReplyForm: false,
      replyText: ''
    }
  },
  mounted() {},
  computed: {
    exportTimeJapan() { return exportTimeJapan },
    body () { return this.comment.comment; },
    lines () { return this.body.split(/\r?\n|<br(?: )*\/?>/); }
  },
  watch: {
    showReplyForm(v) {
      if (v) {
        // focus the end of text area
        // has to wait for the next tick because of v-if
        this.$nextTick(() => {
          this.$refs.commentTextArea.focusEnd?.();
        })
      }
    }
  },
  methods: {
    quoteToReply() {
      this.replyText = this.replyText + (this.replyText ? '\n' : '') + this.lines.map(line => '>' + (line.startsWith('>') ? '' : ' ') + line).join('\n');
      if (this.comment.commentBy.mentionName) {
        this.replyText += `\n@${this.comment.commentBy.mentionName} \n`;
      }
      this.showReplyForm = true;
    },
    sendReply() {
      this.$emit('comment', {
        comment: this.replyText
      })
      this.replyText = '';
      this.showReplyForm = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-quote {
  font-size: 15px;
  // color: #8a8d8c
  color: #3faf7c;
}

.avatar {
  width: 60px;
  height: 60px;
  border: 1px solid #21212131;
  border-radius: 100px;
}
</style>