<template>
  <div class="v-textarea client-comment-outer">
    <text-expander keys="@ # ＠ ＃" ref="textExpander" class="client-comment-expander">
      <textarea
        ref="textarea" class="client-comment-textarea" rows="5"
        v-model="textValue" @change="emitInput"
        @focus="textAreaFocus"
        @blur="textAreaBlur"
      />
    </text-expander>
    <v-alert type="error" outlined dense :value="exceedsMaxLength">最大{{maxLength | toThousands}}文字まで入力可能です (現在{{currentLength | toThousands}}字)</v-alert>
    <v-menu
      v-model="menuTips"
      absolute 
      :position-x="menuX"
      :position-y="menuY"
      :close-on-click="false"
      :close-on-content-click="false"
      offset-y>
      <v-card>「@」で個人へのメンション、「#」でグループへのメンションが可能です。</v-card>
    </v-menu>
  </div>
</template>

<script>
import TextExpanderElement from '@github/text-expander-element'
import commentMixin from './comment-mixin'

export default {
  mixins: [commentMixin],
  props: {
    value: String
  },
  data() {
    return {
      template: null,
      textarea: null,
      maxLength: 10000,
      menuTips: false,
      menuX: null,
      menuY: null,
      timeout: null
    }
  },
  beforeDestroy() {
    if (this.template) {
      this.template.remove();
    }
  },
  computed: {
    textValue: {
      get() {return this.value; },
      set(v) { this.$emit('input', v); }
    },
    currentLength () {
      return this.value?.length ?? 0
    },
    exceedsMaxLength () {
      return (this.currentLength) > this.maxLength
    }
  },
  mounted() {
    this.template = document.createElement('template')
    if (!window.customElements.get('text-expander')) {
      window.TextExpanderElement = TextExpanderElement;
      window.customElements.define('text-expander', TextExpanderElement);
    }
    this.textarea = this.$refs.textExpander.querySelector('textarea');

    this.$refs.textExpander.addEventListener('text-expander-change', (event) => {
      const {key, provide, text} = event.detail
      let suggestions;
      switch (key) {
        case '@':
        case '＠': suggestions = this.getUserMentionSuggestion(text); break;
        case '#':
        case '＃': suggestions = this.getGroupMentionSuggestion(text); break;
      }

      provide(Promise.resolve({matched: suggestions.childElementCount > 0, fragment: suggestions}))
    })
    this.$refs.textExpander.addEventListener('text-expander-value', (event) => {
      const {item}  = event.detail
      event.detail.value = item.getAttribute('data-value')
      this.$nextTick(() => {
        this.emitInput();
      });
    })
  },
  methods: {
    createSuggestions(items) {
      const listItems = items.map(item => {
        return `<li role="option" data-value="${item.value}">${item.text}</li>`
      })
      const html = `<ul class="client-comment-mention-suggestions" style="position: absolute;">
${listItems.join('')}
</ul>`
      this.template.innerHTML = html.trim();

      return this.template.content.firstChild;
    },
    getUserMentionSuggestion(name) {
      name = name.toLowerCase().replace(/[ ]/g, '');
      return this.createSuggestions(this.staffs.filter(staff => {
        if (staff.mentionName.toLowerCase().includes(name)) {
          return true;
        }
        if (staff.name.toLowerCase().replace(/[ ]/g, '').includes(name)) {
          return true;
        }
        return false;
      }).map(staff => ({value: '@' + staff.mentionName, text: staff.name})));
    },
    getGroupMentionSuggestion(name) {
      name = name.toLowerCase().replace(/[ ]/g, '');
      return this.createSuggestions(this.groups.filter(group => {
        if (group.name.toLowerCase().replace(/[ ]/g, '').includes(name)) {
          return true;
        }
        return false;
      }).map(group => ({value: '#' + group.name, text: group.name})));
    },
    emitInput() {
      this.textValue = this.$refs.textarea.value;
    },
    focusEnd() {
      this.textarea.focus();
      this.textarea.setSelectionRange(this.textarea.value.length, this.textarea.value.length)
    },
    textAreaFocus (e) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      const {top, left} = e.target.getBoundingClientRect();
      this.menuX = left
      this.menuY = top - 25
      this.menuTips = true;
      this.timeout = setTimeout(() => {
        this.menuTips = false
      }, 5000);
    },
    textAreaBlur() {
      this.menuTips = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.client-comment-outer {
  position: relative;
}
.client-comment-textarea{
  padding: 10px 12px;
  border: 1px solid darkgray;
  border-radius: 7px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  background-color: #F5FBF8;
}
</style>

<style lang="scss">
.client-comment-mention-suggestions {
  padding-left: 0px !important;
  z-index: 999;
  margin-top:32px;
  background-color: white;
  border: 1px darkgrey solid;
  border-radius: 7px;
  list-style-type: none;

  max-width: 250px;
  max-height: 300px;
  overflow: auto;
  white-space: nowrap;
  
  & li {
    cursor: pointer;
    padding: 6px 12px;
    &:hover {
      background-color: lightgray;
    }
  }
}
</style>