<template>
  <div>
    <customer-details>
      <template v-slot:btnPrope>
        <v-btn
          style="font-size: 12px !important; height:32px !important; width:68px !important"
          class="t-btn--prm mr-5 ml-2"
          :disabled="!checkPerUser"
        >
          保存
        </v-btn>
      </template>
      <!-- <div>dsfdsfdsff</div> -->
      <template v-slot:default="typeClient">
        <div>
          <!-- Contract information -->
          <div
            class="mt-2 mb-4"
            v-if="typeClient.typeClient.trim() !== 'TENTATIVE'"
          >
            <h4 class="text-10px mb-1">契約情報</h4>
            <v-card>
              <Table
                :attr="{
                  dense: true,
                  'item-key': 'id',
                  class: 'scroll-table --md',
                }"
                ref="tableContractList"
                :itemsPerPage="30"
                :itemsPerPageOptions="[30, 60, 120, 480]"
                :headers="headerContractInformation"
                :items="customerDetailListAll.contractList"
                :funReset="fetchContractList"
                :hideFooter="true"
                :disablePagination="true"
              >
                <template v-slot:[`item.productType.name`]="{ item }">
                  {{ item.productType && item.productType.code }}
                </template>
                <template v-slot:[`item.phaseName`]="{ item }">
                  {{ item.phase && item.phase.name }}
                </template>
              </Table>
            </v-card>
          </div>
          <!-- End Contract information -->

          <!-- Corporate ticket -->
          <div
            v-if="customerDetailListAll.clientBasicInfo.isCompany"
            class="my-5"
          >
            <div class="d-flex justify-space-between mb-1">
              <h2 class="text--title">法人チケット</h2>
              <v-btn
                class="t-btn--prm"
                :disabled="!checkPerUser"
                style="font-size: 12px !important; height:32px !important; width:162px !important"
                @click="
                  isShowCreateNew = true;
                  idx = idx + 1;
                "
              >
                <span
                  ><i class="fas fa-plus"></i>&emsp;新規法人チケット作成</span
                >
              </v-btn>
            </div>
            <v-card>
              <Table
                :attr="{
                  dense: true,
                  'item-key': 'id',
                  class: 'scroll-table --md',
                }"
                ref="tableBookingTicketTypeList"
                :headers="headerCorporateTicket"
                :items="customerDetailListAll.bookingTicketTypeList"
                :funReset="fetchBookingTicketTypeList"
                :hideFooter="true"
                :disablePagination="true"
              >
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{
                    item.createdAt &&
                      item.createdAt
                        .substr(0, 10)
                        .split('T')
                        .join(' ')
                        .split('-')
                        .join('/')
                  }}
                </template>
                <template v-slot:[`item.issuedCount`]="{ item }">
                  {{ item.computed && item.computed.issuedCount }}
                </template>
                <template v-slot:[`item.roomTypeNames`]="{ item }">
                  {{ getRoomTypeNameList(item.roomTypes) }}
                </template>
                <template v-slot:[`item.cancelDeadline`]="{ item }">
                  失効期限{{ item.cancelDeadline }}日前
                </template>
                <template v-slot:[`item.bookingFromMonths`]="{ item }">
                  宿泊日から{{ item.bookingFromMonths }}か月前
                </template>
                <template v-slot:[`item.bookingFromDays`]="{ item }">
                  宿泊日から{{ item.bookingFromDays }}日前
                </template>
                <template v-slot:[`item.usedCount`]="{ item }">
                  {{ item.computed && item.computed.usedCount }}
                </template>
                <template v-slot:[`item.availableCount`]="{ item }">
                  {{ item.computed && item.computed.availableCount }}
                </template>
                <template v-slot:[`item.edit`]="{ item }">
                  <v-btn icon @click="fetchDetailData(item.id)">
                    <v-icon x-large class="btn-crm-primary"
                      >mdi-pencil-circle</v-icon
                    >
                  </v-btn>
                </template>
              </Table>
            </v-card>
          </div>
          <!-- End Corporate ticket -->

          <!-- List Data -->
          <div class="ml-3 mt-9">
            <v-row>
              <v-col cols="12" lg="4" md="6" class="pl-lg-0">
                <!-- Is Personal -->
                <div
                  v-if="!customerDetailListAll.clientBasicInfo.isCompany"
                  class="t-wrapper elevation-3 rounded"
                >
                  <h4 class="px-2 pt-3 text-10px">
                    基本情報(
                    {{
                      customerDetailListAll.clientBasicInfo.isCompany
                        ? '法人'
                        : '個人'
                    }}
                    )
                  </h4>
                  <div class="mx-5">
                    <div class="t-table">
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >顧客番号</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.id
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >顧客名</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.name
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >フリガナ</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.nameKana
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.isCompany
                              ? '法人窓口'
                              : '性別'
                          }}</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                        >
                          <gender :value="customerDetailListAll.clientBasicInfo.sex" />
                        </strong>
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.isCompany
                              ? 'フリガナ'
                              : '生年月日-年齢'
                          }}</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                        >
                          <span
                            v-if="
                              customerDetailListAll.clientBasicInfo.birthday
                            "
                          >
                            {{
                              customerDetailListAll.clientBasicInfo.birthday &&
                                `${
                                  customerDetailListAll.clientBasicInfo.birthday.split(
                                    '-',
                                  )[0]
                                }年${
                                  customerDetailListAll.clientBasicInfo.birthday.split(
                                    '-',
                                  )[1]
                                }月${
                                  customerDetailListAll.clientBasicInfo.birthday.split(
                                    '-',
                                  )[2]
                                }日`
                            }}
                            <span
                              v-if="
                                customerDetailListAll.clientBasicInfo.birthday
                              "
                            >
                              &nbsp;{{
                                getAgeFromDate(
                                  customerDetailListAll.clientBasicInfo
                                    .birthday,
                                )
                              }}歳
                            </span>
                          </span>
                          <span v-else> - </span>
                        </strong>
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.isCompany
                              ? '担当者生年月日-年齢'
                              : '都道府県-市区郡'
                          }}</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                        >
                          {{
                            customerDetailListAll.clientBasicInfo.mainContact &&
                              customerDetailListAll.clientBasicInfo.mainContact
                                .prefecture
                          }}
                          -
                          {{
                            customerDetailListAll.clientBasicInfo.mainContact &&
                              customerDetailListAll.clientBasicInfo.mainContact
                                .locality
                          }}
                        </strong>
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.isCompany
                              ? '都道府県-市区郡'
                              : '電話連絡先電話番号'
                          }}</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                        >
                          {{
                            customerDetailListAll.clientBasicInfo.mainContact &&
                              customerDetailListAll.clientBasicInfo.mainContact
                                .tel
                          }}
                        </strong>
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.isCompany
                              ? '電話連絡先電話番号'
                              : '職業'
                          }}</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo
                              .employmentType &&
                              customerDetailListAll.clientBasicInfo
                                .employmentType.name
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.isCompany
                              ? '法人窓口担当者部署 - 役職名'
                              : '子供'
                          }}</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.childType &&
                              customerDetailListAll.clientBasicInfo.childType
                                .name
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.isCompany
                              ? '従業員数'
                              : '年収'
                          }}</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.incomeLevel &&
                              customerDetailListAll.clientBasicInfo.incomeLevel
                                .name
                          }}</strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ----------- -->
                <!-- Is Company -->
                <div v-else class="t-wrapper elevation-3 rounded">
                  <h4 class="px-2 pt-3 text-10px">基本情報(法人)</h4>
                  <div class="mx-5">
                    <div class="t-table">
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >顧客番号</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.id
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >顧客名</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.companyName
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >フリガナ</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo
                              .companyNameKana
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >法人窓口</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.companyPicName
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >フリガナ</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.companyPicKana
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >都道府県-市区郡</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.mainContact &&
                              customerDetailListAll.clientBasicInfo.mainContact
                                .prefecture
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          class="text-10px"
                          style="color: #757575 !important"
                          >電話連絡先電話番号</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.mainContact &&
                              customerDetailListAll.clientBasicInfo.mainContact
                                .tel
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          style="color: #757575 !important"
                          class="text-10px"
                          >法人窓口担当者部署 - 役職名</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                        >
                          {{
                            customerDetailListAll.clientBasicInfo
                              .companyPicDepartment
                          }}
                          -
                          {{
                            customerDetailListAll.clientBasicInfo
                              .companyPicTitle
                          }}</strong
                        >
                      </div>
                      <div>
                        <label
                          style="color: #757575 !important"
                          class="text-10px"
                          >従業員数</label
                        >
                        <strong
                          class="text-15px"
                          style="color: #424242 !important"
                          >{{
                            customerDetailListAll.clientBasicInfo.companySize
                          }}</strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ----------- -->
              </v-col>
              <!-- 1 -->
              <v-col
                cols="12"
                lg="4"
                md="6"
                v-if="typeClient.typeClient.trim() !== 'TENTATIVE'"
              >
                <div class="t-wrapper elevation-3 rounded">
                  <h4 class="px-2 pt-3 text-10px">副会員情報</h4>
                  <Table
                    :attr="{
                      dense: true,
                      'item-key': 'id',
                      class: 'mx-3 table-custom table-dialog-custom',
                    }"
                    ref="tableClientMemberInfoSubMembers"
                    :itemsPerPage="10"
                    :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
                    :headers="headerViceMemberInformation"
                    :total="
                      customerDetailListAll.clientMemberInfo.subMembers
                        ? parseInt(
                            customerDetailListAll.clientMemberInfo.subMembers
                              .length,
                          )
                        : null
                    "
                    :items="customerDetailListAll.clientMemberInfo.subMembers"
                    :disableSort="true"
                    :disableServerItemsLength="true"
                  >
                    <template v-slot:[`item.subMemberType.name`]="{ item }">
                      <span
                        class="text-10px"
                        style="color: #757575 !important"
                        >{{ item.subMemberType.name }}</span
                      >
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                      <span
                        class="text-15px"
                        style="color: #424242 !important"
                        >{{ item.name }}</span
                      >
                    </template>
                    <template v-slot:[`item.nameKana`]="{ item }">
                      <span
                        class="text-10px"
                        style="color: #757575 !important"
                        >{{ item.nameKana }}</span
                      >
                    </template>
                    <template v-slot:[`item.relationshipName`]="{ item }">
                      <span
                        class="text-15px"
                        style="color: #424242 !important"
                        >{{ item.relationship && item.relationship.name }}</span
                      >
                    </template>
                    <template v-slot:[`item.age`]="{ item }">
                      <span class="text-15px" style="color: #424242 !important">
                        {{ item.birthday && getAgeFromDate(item.birthday) }}
                      </span>
                    </template>
                  </Table>
                  <h4 class="px-2 pt-7 text-10px">紹介者情報</h4>
                  <v-simple-table
                    v-if="customerDetailListAll.clientMemberInfo.referrerClient"
                  >
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>紹介者</td>
                          <td>
                            {{
                              customerDetailListAll.clientMemberInfo
                                .referrerClient.name
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>
              <!-- END 1 -->
              <!-- 2 -->
              <v-col cols="12" lg="4" md="6">
                <div class="t-wrapper elevation-3 rounded">
                  <h4 class="px-2 pt-3 text-10px">
                    備考
                  </h4>
                  <p style="max-height: 300px; overflow: auto;" class="mx-2">
                    <tvos-format-span :value="customerDetailListAll.clientBasicInfo.remarks" />
                  </p>
                </div>
              </v-col>
              <!-- END 2 -->
            </v-row>
          </div>
          <!-- End List data -->

          <!-- Reservation information -->
          <!-- 3 -->
          <h4 class="text-10px mt-5">予約情報</h4>
          <overview-booking-list :clientId="clientID" />
          <!-- END 3 -->
          <!-- End Reservation information -->

          <!-- Availability notification -->
          <div class="mt-5" v-if="typeClient.typeClient.trim() !== 'TENTATIVE'">
            <div class="d-flex justify-space-between">
              <h4 class="text-10px mt-5">空き通知</h4>
              <v-btn
                :disabled="!checkPerUser"
                class="t-btn--prm mb-2"
                style="font-size: 12px !important; height:32px !important; width:116px !important"
                @click="visible = 2"
              >
                <span><i class="fas fa-plus"></i>&emsp;新規空き通知</span>
              </v-btn>
            </div>
            <v-card>
              <Table
                :attr="{
                  dense: true,
                }"
                :itemsPerPage="5"
                :itemsPerPageOptions="[5, 10, 30, 50]"
                :headers="headerWaitlist"
                :items="waitListItems"
              >
                <template v-slot:[`item.kinds`]="{ item }">
                  {{ item.type === 'room' ? '予約' : '駐車場' }}
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ item.createdAt | formatDateTimeISONoSecond }}
                </template>
                <template v-slot:[`item.lastUpdatedAt`]="{ item }">
                  {{ item.lastUpdatedAt | formatDateTimeISONoSecond }}
                </template>
                <template v-slot:[`item.bookingId`]="{ item }">
                  {{ item.booking? item.booking.id : '' }}
                </template>
                <template v-slot:[`item.bookingEdit`]="{ item }">
                  <v-btn
                    v-if="item.booking"
                    :disabled="!checkPerUser"
                    @click="$openRoute.booking(item.booking.id)"
                    class="btn-crm-primary"
                    small
                  >
                    編集
                  </v-btn>
                </template>
                <template v-slot:[`item.facilityName`]="{ item }">
                  {{
                    item.roomType.facility && item.roomType.facility.name
                  }}
                </template>
                <template v-slot:[`item.roomTypeName`]="{ item }">
                  {{
                    item.roomType.name
                  }}
                </template>
                <template v-slot:[`item.checkInDate`]="{ item }">
                  {{
                    item.checkInDate | formatDate
                  }}
                </template>
                <template v-slot:[`item.checkOutDate`]="{ item }">
                  {{
                    item.checkOutDate | formatDate
                  }}
                </template>
                <template v-slot:[`item.days`]="{ item }">
                  {{
                    calculateDays(item.checkOutDate, item.checkInDate)
                  }}
                </template>
                <template v-slot:[`item.fromDate`]="{ item }">
                  {{
                    item.fromDate | formatDate
                  }}
                </template>
                <template v-slot:[`item.toDate`]="{ item }">
                  {{
                    item.toDate | formatDate
                  }}
                </template>
                <template v-slot:[`item.delete`]="{ item }">
                  <v-btn v-if="item.type === 'room'" @click="deleteCancelWaitList(item)" class="t-btn--red-dark" icon>
                    <i class="fas fa-comment-slash"></i>
                  </v-btn>
                  <v-btn v-else
                    :disabled="!checkPerUser"
                    @click="deleteParkingCancelWaitList(item)"
                    class="t-btn--red-dark"
                    icon
                  >
                    <i class="fas fa-comment-slash"></i>
                  </v-btn>
                </template>
                
              </Table>
            </v-card>
          </div>
          <!-- End Availability notification -->

          <br />
          <br />
          <!-- Comment -->
          <div class="mb-16">
            <v-card outlined>
              <div class="listCmt">
                <!-- cmt -->
                <comment-list
                  :data="customerDetailListAll.paginateClientComment"
                ></comment-list>
                <!-- end cmt -->
              </div>
            </v-card>
          </div>
          <!-- End Comment -->

          <free-notification-registration
            :visible="visible === 2"
            ref="form"
            @close="visible = -1"
            :id="clientID"
          ></free-notification-registration>
          <issuance-of-new-corporate-tickets
            :key="idx"
            v-if="isShowCreateNew"
            :showDialog="isShowCreateNew"
            @close="isShowCreateNew = false"
          ></issuance-of-new-corporate-tickets>
          <!-- End Dialog -->
        </div>

        <corporate-ticket-detail
          :visible="isOpenTicketDialog"
          @close="visible = isOpenTicketDialog = false"
          @changeStatus="fetchDetailData(currentId)"
        ></corporate-ticket-detail>
      </template>
    </customer-details>
  </div>
</template>

<script>
import CustomerDetails from '../CustomerDetails.vue';
import FreeNotificationRegistration from './FreeNotificationRegistration.vue';
import IssuanceOfNewCorporateTickets from './IssuanceOfNewCorporateTickets.vue';
import { calculateDays, getAgeFromDate } from '@/utils/dateUtil';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CommentList from './commentList.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { handlErrorView, formatDate3, getCopyErrorTextView } from '@/constants/functions';
import moment from 'moment';
import CorporateTicketDetail from '../../../../corporateticket/CorporateTicketDetail.vue';
import gql from 'graphql-tag';
import OverviewBookingList from './OverviewBookingList.vue';
import Table from '@/components/Table/index.vue';
import Gender from '@/components/shared/Gender.vue';

export default {
  // CustomerDetails
  components: {
    FreeNotificationRegistration,
    IssuanceOfNewCorporateTickets,
    CustomerDetails,
    CommentList,
    CorporateTicketDetail,
    OverviewBookingList,
    Table,
    Gender
  },
  name: 'customer-details-overview',
  data() {
    return {
      currentId: null,
      isOpenTicketDialog: false,
      idx: 0,
      itemsData: {
        clientChangeHistoryList: [],
      },
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      isShowCreateNew: false,
      clientID: parseInt(this.$route.params.id),
      visible: -1,
      // Table Contract information
      headerContractInformation: [
        {
          text: this.$t('task_29786.status'),
          value: 'status',
          width: '150px',
        },
        {
          text: this.$t('task_29786.ContractProduct'),
          value: 'productType.name',
          width: '50px',
        },
        {
          text: '施設',
          value: 'computedFacility.facilityName',
          width: '200px',
        },
        {
          text: this.$t('task_29786.ContractNumber'),
          value: 'contractNumber',
          width: '150px',
        },
        {
          text: this.$t('task_29786.Phase'),
          value: 'phaseName',
          width: '150px',
        },
        {
          text: this.$t('task_29786.ContractStartYear'),
          value: 'startDate',
          width: '150px',
        },
        {
          text: this.$t('task_29786.ContractTerminationYear'),
          value: 'endDate',
        },
      ],
      // Table Corporate ticket
      headerCorporateTicket: [
        {
          text: this.$t('task_29786.dateOfIssue'),
          value: 'createdAt',
          width: '74px',
        },
        {
          text: this.$t('task_29786.TicketNo'),
          value: 'id',
          width: '76px',
        },
        {
          text: this.$t('task_29786.NumberOfIssues'),
          value: 'issuedCount',
          width: '48px',
        },
        {
          text: this.$t('task_29786.name'),
          value: 'name',
          width: '203px',
        },
        {
          text: this.$t('task_29786.institution'),
          value: 'roomTypeNames',
          width: '28px',
        },
        {
          text: this.$t('task_29786.ExpirationDate'),
          value: 'cancelDeadline',
          width: '78px',
        },
        {
          text: this.$t('task_29786.ReservationStartDate'),
          value: 'bookingFromDate',
          width: '74px',
        },
        {
          text: this.$t('task_29786.ReservationPeriod') + ' (月)',
          value: 'bookingFromMonths',
          width: '114px',
        },
        {
          text: this.$t('task_29786.ReservationPeriod') + ' (日)',
          value: 'bookingFromDays',
          width: '114px',
        },
        {
          text: this.$t('task_29786.NumberOfSheetsUsed'),
          value: 'usedCount',
          width: '48px',
        },
        {
          text: this.$t('task_29786.Available'),
          value: 'availableCount',
          width: '48px',
        },
        {
          text: '詳細',
          value: 'edit',
          width: '50.69px',
        },
      ],
      // Vice Member Information
      headerViceMemberInformation: [
        {
          text: '',
          value: 'subMemberType.name',
          align: 'center',
        },
        {
          text: '',
          value: 'name',
          align: 'center',
        },
        {
          text: this.$t('task_29786.Furigana'),
          value: 'nameKana',
          align: 'center',
        },
        {
          text: this.$t('task_29786.relationship'),
          value: 'relationshipName',
          align: 'center',
        },
        {
          text: this.$t('task_29786.age'),
          value: 'age',
          align: 'center',
        },
      ],
      headerWaitlist: [

      {
          text: this.$t('task_29786.NotificationMember'),
          value: 'notifyToType',
        },
        {
          text: this.$t('task_29786.NotificationMemberName'),
          value: 'notifyToName',
        },
        {
          text: this.$t('task_29786.kinds'),
          value: 'kinds',
        },
        {
          text: this.$t('task_29786.RegistrationDate'),
          value: 'createdAt',
        },
        {
          text: 'ステータス',
          value: 'status',
        },
        {
          text: '更新日時',
          value: 'lastUpdatedAt',
        },
        {
          text: this.$t('task_29786.ReservationID'),
          value: 'bookingId',
        },
        {
          text: this.$t('task_29786.ReservationEdit'),
          value: 'bookingEdit',
        },
        {
          text: this.$t('task_29786.institution'),
          value: 'facilityName',
        },
        {
          text: '部屋タイプ',
          value: 'roomTypeName',
        },
        {
          text: this.$t('task_29786.checkIn'),
          value: 'checkInDate',
        },
        {
          text: this.$t('task_29786.LastDay'),
          value: 'checkOutDate',
        },
        {
          text: this.$t('task_29786.NumberOfNights'),
          value: 'days',
        },
        {
          text: this.$t('task_29786.ParkingStartDate'),
          value: 'fromDate',
        },
        {
          text: this.$t('task_29786.LastDayOfParking'),
          value: 'toDate',
        },
        {
          text: this.$t('task_29786.CancelNotification'),
          value: 'delete',
          align: 'center',
        },
      ],
      // Reservation information
      ReservationInformation: [],
      ready: false,
      contractPagi: {
        itemsPerPage: 10,
        totalCount: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      'customerDetailListAll',
    ]),
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
    waitListItems () {
      return this.customerDetailListAll.cancelWaitList.map(cw => {
        return {
          type: 'room',
          ...cw,
        }
      }).concat(this.customerDetailListAll.facilityParkingCancelWaitList.map(pw => {
        return {
          type: 'parking',
          ...pw,
          roomType: pw.booking.room.roomType,
          checkInDate: pw.booking.checkInDate,
          checkOutDate: pw.booking.checkOutDate,
        }
      }))
    }
  },
  async mounted() {
    this.ready = true;
    // table 契約情報
    if (this.$refs.tableContractList) {
    await this.$refs.tableContractList.reset();
    }
    // table 法人チケット
    if (this.customerDetailListAll.clientBasicInfo.isCompany) {
      await this.$refs.tableBookingTicketTypeList.reset();
    }
    // table 副会員情報
    this.fetchClientMemberInfo();
    // table 担当者情報
    await this.fetchClientBasicInfo();
    this.fetchCancelWaitList();

    // TENTATIVE
    this.actionClientChangeHistoryListTake3Records().then(result => {
      this.itemsData.clientChangeHistoryList = result;
    });
  },

  methods: {
    getAgeFromDate,
    ...mapMutations(['setAlertSuccess', 'setAlertError']),
    async deleteCancelWaitList(item) {
      const query = `
            mutation ($id: Int!) {
              deleteCancelWaitList(id: $id) {
                id
              }
            }
          `;
      const variables = {
        id: parseInt(item.id),
      };
      await this.$apollo
        .mutate({
          mutation: gql`
            ${query}
          `,
          variables: variables,
        })
        .then(data => {
          this.setAlertSuccess('削除しました。');
          this.$store.dispatch('fetchCancelWaitList');
          // this.clientName =  data.data.clientBasicInfo.name
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(query, variables, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
    async deleteParkingCancelWaitList(item) {
      const query = `
            mutation ($id: Int!) {
              cancelFacilityParkingCancelWaitList(facilityParkingWaitlistId: $id) {
                id
              }
            }
          `;
      const variables = {
        id: item.id,
      };
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then(data => {
          this.setAlertSuccess('削除しました。');
          this.$store.dispatch('fetchCancelWaitList');
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(query, variables, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
    calculateDays,
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),
    ...mapActions([
      'getPaginateClientComment',
      'fetchBookingList',
      'fetchContractList',
      'fetchBookingTicketTypeList',
      'fetchClientChangeHistoryList',
      'fetchClientMemberInfo',
      'fetchClientBasicInfo',
      'fetchCancelWaitList',
      'actionClientChangeHistoryListTake3Records',
      'fetchBookingTicketType',
    ]),

    async fetchDetailData(id) {
      await this.fetchBookingTicketType(id);
      this.currentId = id;
      this.isOpenTicketDialog = true;
    },

    formatDate0(date) {
      return moment(date).format('YYYY/MM/DD');
    },
    formatDate1(date) {
      return moment(date).format('YYYY/M/DD');
    },
    formatDate2(date) {
      return moment(date).format('YYYY/M/DD HH:MM');
    },
    dateOfBirthAndAge(birthdayDate) {
      if (birthdayDate) {
        const birthday = formatDate3(birthdayDate);
        var yearsOld = moment().diff(birthdayDate, 'years');
        return `${birthday} ${yearsOld}歳`;
      }
      return null;
    },

    getRoomTypeNameList(arr) {
      const nameList = [];
      arr?.length > 0 &&
        arr.map(item => {
          if (item.roomType.facility) {
            nameList.push(item.roomType.facility.name);
          }
        });
      return nameList.join(', ');
    },

  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .table-reservation-info {
    tr {
      white-space: nowrap;
      th {
        padding-right: 0px !important;
        font-size: 12px !important;
        color: #757575 !important;
      }
    }
  }
  .text-10px {
    font-size: 10px !important;
    color: #212121 !important;
  }
  .scroll-table {
    overflow-y: auto;
    &.--xs {
      max-height: 240px !important;
    }
    &.--md {
      max-height: 285px !important;
    }
  }
  .table-dialog-custom {
    * {
      word-break: keep-all;
    }
    tr {
      th {
        font-size: 12px !important;
        white-space: pre;
        height: 22.5px !important;
      }
      td:nth-child(2),
      td:nth-child(5),
      td:nth-child(4) {
        font-size: 15px !important;
        font-weight: bolder;
      }
      td:nth-child(3) {
        font-weight: bolder;
      }
      td {
        padding: 0 !important;
        height: 22.5px !important;
        font-size: 10px !important;
      }
    }
    .v-data-footer__pagination {
      display: none !important;
    }
  }
  .table-person-charge-dialog-custom {
    * {
      word-break: keep-all;
    }
    tr {
      th {
        font-size: 12px !important;
        height: 30px !important;
      }
      td {
        height: 30px !important;
      }
    }
    .v-data-footer__pagination {
      display: none !important;
    }
  }
  // Dialog
  .v-dialog {
    background-color: var(--bg_white);
    max-width: 1400px;
  }
  .sort-select {
    max-width: 180px;
  }
  .v-chip {
    width: 100%;
    color: var(--text_white);
    justify-content: center;
  }
}
.t-wrapper {
  height: 100%;
  background-color: var(--bg_white);
}
.t-table {
  border-spacing: 0 10px;
  padding: 16px;
  &:not(.text) {
    display: table;
    width: 100%;
    > div {
      display: table-row;
      label,
      strong,
      .t-table__cell {
        display: table-cell;
      }
      strong,
      .t-table__cell {
        text-align: right;
      }
    }
  }
}
a.d-block {
  color: var(--text_primary);
}
.text-15px {
  font-size: 15px;
}
.col-custom {
  width: 100px;
}
.listCmt {
  // width: 1300px;
  margin: auto;
  padding: 10px 0;
}
.hr-custom {
  border-top: 1px solid #eaecef;
}
</style>
<style lang="scss">
.table-custom-w {
  tr th:not(:last-child),
  tr td:not(:last-child) {
    min-width: 200px !important;
    width: 200px !important;
  }
  tr th:first-child,
  tr td:first-child {
    min-width: 170px !important;
    width: 170px !important;
  }
}
</style>
