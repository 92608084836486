<template>
  <div>
    <i
      class="fa-heart"
      :class="likedByMe && checklikedByStaff ?'fas':'far'"
      style = "font-size: 15px; color: #3faf7c"
      @click="toggleLike"
    >
    </i>
    <span v-if="likedBy.length" style="color: #3faf7c">
      {{likedBy.length}}
      <span v-for="(staff, ind) in likedBy" :key="ind">
        {{staff.name}}<template v-if="ind < likedBy.length - 1">, </template>
      </span>
    </span>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { handlErrorView } from '@/constants/functions.js';

export default {
  props: {
    comment: Object,
    page: String
  },
  data() {
    return {
      localLiked: false,
    }
  },
  mounted () {
    this.localLiked = !!this.comment.likedByStaff;
  },
  computed: {
    likedByMe() { return this.localLiked; },
    likedBy () { return this.comment.likesByStaffs?.map(row => row.staff) ?? []; },
    commentId () {return this.comment.id; },
    checklikedByStaff(){
      if(this.page === 'overview'){
        if(this.comment.likedByStaff){
          return true
        }
        return false
      }else{
        if(this.comment.likesByStaffs){
          return true
        }
        return false
      }
    },
  },
  methods: {
    async toggleLike() {
      if (this.likedByMe) {
        // unlike this comment
        await this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: Int!) {
                unlikeClientComment(commentId: $id)
              }
            `,
            variables: {
              id: this.commentId,
            },
          })
          .then(() => {
            this.localLiked = false
            this.$emit('reset')
            this.$store.dispatch('getCommetListMyPage');
          })
          .catch(async (error) => {
            const errorTmp = await handlErrorView(
              error.graphQLErrors,
              this.setPermissionUser,
              this.setRoleAdminUser,
            );
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true });
            }
          });      
      } else {
        // like this comment
        await this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: Int!) {
                likeClientComment(commentId: $id)
              }
            `,
            variables: {
              id: this.commentId,
            },
          })
          .then((data) => {
            this.localLiked = true
            this.$emit('reset')
            this.$store.dispatch('getCommetListMyPage');
          })
          .catch(async (error) => {
            const errorTmp = await handlErrorView(
              error.graphQLErrors,
              this.setPermissionUser,
              this.setRoleAdminUser,
            );
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true });
            }
          });
      }
    }
  }
}
</script>