<template>
  <span>
    <template v-for="(piece, index) in pieces">
      <span class="mention staff-mention" v-if="isStaffMention(piece)" :key="index" v-text="'@' + isStaffMention(piece).name" />
      <span class="mention group-mention" v-else-if="isGroupMention(piece)" :key="index" v-text="'#' + isGroupMention(piece).name" />
      <span class="" v-else :key="index" v-text="piece" />
    </template>
  </span>
</template>

<script>
import commentMixin from './comment-mixin'

export default {
  mixins: [commentMixin],
  props: {
    message: String
  },
  computed: {
    pieces() {
      // eslint-disable-next-line no-irregular-whitespace
      return this.message.split(/(?:^|[\s　])((?:@|#)[^\s　]+)/)
    }
  },
  methods: {
    isStaffMention(piece) {
      if (piece.startsWith('@')) {
        const staff = this.staffs.find(staff => staff.mentionName === piece.substring(1))
        return staff;
      }
      return false;
    },
    isGroupMention(piece) {
      if (piece.startsWith('#')) {
        const group = this.groups.find(group => group.name === piece.substring(1))
        return group;
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
.mention {
  padding-left: 5px;
  padding-right: 5px;
  color: rgb(63, 175, 124);
}
</style>