var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{ref:"table",attrs:{"attr":{
    dense: true,
    'item-class': _vm.itemRowBackground,
    'item-key': 'id',
  },"multiSort":true,"itemsPerPage":10,"itemsPerPageOptions":[5, 10, 20, 50, 100, 200, 500],"headers":_vm.headers,"total":_vm.total,"items":_vm.bookings,"funReset":_vm.fetchData},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{staticClass:"bg--white",staticStyle:{"min-width":"40px !important","width":"40px !important","height":"24px !important"},attrs:{"disabled":!_vm.checkPerUser,"outlined":"","small":"","target":"_blank","color":"#1873D9"},on:{"click":function($event){return _vm.$openRoute.booking(item.id)}}},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm.$t('buttons.edit')))])])]}},{key:"item.computed.status",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.computed ? item.computed.status : '')+" ")]}},{key:"item.bookingType.code",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.bookingType.code)+" ")]}},{key:"item.room.roomType.facility.name",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.room.roomType.facility ? item.room.roomType.facility.name : '')+" ")]}},{key:"item.room.roomType.name",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.room.roomType.name)+" ")]}},{key:"item.room.name",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.room.name)+" ")]}},{key:"item.bookingPlan.title",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.bookingPlan ? item.bookingPlan.title : '')+" ")]}},{key:"item.checkInDate",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.checkInDate)+" ")]}},{key:"item.checkOutDate",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.checkOutDate)+" ")]}},{key:"item.representative.name",fn:function(ref){
  var item = ref.item;
return [(item.representative)?[_vm._v(" "+_vm._s(item.representative.name ? item.representative.name : '')+" ")]:_vm._e()]}},{key:"item.adult",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.computedForList.adults)+" ")]}},{key:"item.child",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.computedForList.children)+" ")]}},{key:"item.bedSharing",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.computedForList.bedShares)+" ")]}},{key:"item.parking",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.computedForList.hasParking ? '有' : '無')+" ")]}},{key:"item.rentalItem",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.computedForList.hasRentalItem ? '有' : '無')+" ")]}},{key:"item.updatedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeISONoSecond")(item.updatedAt))+" ")]}},{key:"item.createdAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeISONoSecond")(item.createdAt))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }