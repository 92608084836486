<template>
  <div class="comment-line">
    <comment-quote v-if="isQuoted" :line="line" :quoteRegex="quoteRegex" />
    <span v-else><CommentLineWithMention :message="line" /></span>
  </div>
</template>

<script>
import CommentQuote from './CommentQuote'
import CommentLineWithMention from './CommentLineWithMention.vue'
export default {
  name: 'CommentLine',
  components: {CommentQuote, CommentLineWithMention},
  props:{ line: String},
  data() {
    return {
      quoteRegex: /^( ){0,1}>/
    }
  },
  computed: {
    isQuoted() {
      if (this.line) {
        return this.quoteRegex.test(this.line)
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-line {
  min-height: 1em;
  word-break: break-all;
}
</style>