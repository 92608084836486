<template>
  <div>
    <div>
      <template>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="#13ace0"></v-tabs-slider>
          <v-tab
            v-for="(item, i) in filteredTabs"
            :key="i"
            :to="item.to + typeClient"
            style="font-size: 14px !important;"
            class="page-title-tab px-13 pt-3"
            >{{ item.title }}
          </v-tab>
        </v-tabs>
        <!-- <slot name="default" /> -->
      </template>
    </div>
  </div>
</template>
<script>
import { CLIENT_BASIC_INFO_CRRENT } from '@/api/graphql/basicInformation/basicInformation'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { filterTabs, toAltRouteIfTabMissing } from '@/utils/permissions'
import { mapMutations } from 'vuex';
import { TYPE_CLIENT } from '@/constants/enum'
import gql from 'graphql-tag'

export default {
  name: 'customer-details-tab',
  data() {
    return {
      TYPE_CLIENT,
      type: '',
      tab: null,
      items: [
        {
          title: '概要',
          to: '/customer-details/' + this.id + '?typeClient=',
        },
        {
          title: '会員契約',
          to: '/customer-details/member-ship-contract/' + this.id + '?typeClient=',
        },
        {
          title: '見込客情報',
          to: '/customer-details/prospect-information/' + this.id + '?typeClient=',
        },
        {
          title: '基本情報',
          to: '/customer-details/basic-information/' + this.id + '?typeClient=',
        },
        {
          title: '登録カード情報',
          to: '/customer-details/credit-card/' + this.id + '?typeClient=',
        },
        {
          title: '活動',
          to: '/customer-details/activities/' + this.id + '?typeClient=',
        },
        {
          title: '請求作成履歴',
          to: '/customer-details/membership-billing-history/' + this.id + '?typeClient=',
        },
        {
          title: '変更履歴',
          to: '/customer-details/change-history/' + this.id + '?typeClient=',
        },
        {
          title: '削除',
          to: '/customer-details/delete/' + this.id + '?typeClient=',
        },
      ],
    };
  },
  computed: {
    filteredTabs () {
      return filterTabs(this.items)
    }
  },
  props: {
    id : {
      type: [String, Number],
      default: 0,
    },
    typeClient: String,
  },
  mounted() {
    this.getUnserInfoClientCurrent()
  },

  methods:{
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setStatusSaveData',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    async getUnserInfoClientCurrent () {
      const variables = {
          id: parseInt(this.id)
         }
      await this.$apollo.query({
        query: gql`${CLIENT_BASIC_INFO_CRRENT}`,
        variables: variables
      }).then((data) => {
        if (data.data.clientBasicInfo) {
          if (data.data.clientBasicInfo.type === TYPE_CLIENT.TENTATIVE) {
            const itemTmp = this.items.filter((ite) => {
              return ite.title !== '会員契約' && ite.title !== '請求作成履歴' && ite.title !== '削除'
            })
            this.items = itemTmp
          }
          if (data.data.clientBasicInfo.type === TYPE_CLIENT.VSINTEREST) {
            const itemTmp = this.items.filter((ite) => {
              return ite.title === '見込客情報'
            })
            this.items = itemTmp
          }
        }
        toAltRouteIfTabMissing(this.items)
      }).catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(CLIENT_BASIC_INFO_CRRENT, variables, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.v-application .primary--text {
  color: #13ace0 !important;
}
.v-slide-group__wrapper {
  .v-tab--active {
    background-color: #ffffff !important;
    color: #13ace0 !important;
  }
}
.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
}

.v-tab {
  background-color: #ffffff;
  color: #aaaaaa;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.v-tab:hover {
  background-color: #eeeeee;
  color: #aaaaaa;
}
// ::v-deep {
//   .v-application .primary--text {
//     color: #13ace0 !important;
//   }

//   .v-slide-group__wrapper {
//     .v-tab--active {
//       background-color: #ffffff !important;
//       color: #13ace0 !important;
//     }
//   }
//   .v-tab--active:hover {
//     background-color: #ceeffb !important;
//     color: #13ace0 !important;
//   }

//   .v-tab {
//     background-color: #ffffff;
//     color: #aaaaaa;
//   }

//   .v-tab:hover {
//     background-color: #eeeeee;
//     color: #aaaaaa;
//   }
// }
</style>
